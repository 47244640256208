<template>
  <v-form ref="unplannedform">
    <v-btn variant="text" prepend-icon="mdi-arrow-left" class="mb-4" @click="$emit('back')">
      <span>Back</span>
    </v-btn>
    <div v-if="isAssetVisitTypeFilled && isAssetContainerDataFilled">
      <h2 class="header">Fill out the check-in form</h2>
      <custom-fields :warehouse="warehouse" :value="assetDetailsFields"></custom-fields>
      <custom-fields
        :warehouse="warehouse"
        v-if="additionalAssetDetailsFieldsExist"
        :value="additionalAssetDetailsFields"></custom-fields>
    </div>

    <div v-else>
      <asset-visit-type-form
        :value="assetVisitType"
        :is-asset-visit-type-filled="isAssetVisitTypeFilled"
        :is-asset-container-data-filled="isAssetContainerDataFilled"
        @asset-visit-type-selected="(v: AssetVisitType) => {
          assetVisitType = v;
          if (v === AssetVisitType.PickUp) {
            isAssetContainerDataFilled = true;
          }
        }"
        @asset-container-data-changed="
          ({ isValid, data } : any) => {
            isAssetContainerDataValid = isValid; 
            assetContainerData = data;
          }"
        class="mb-4" />
    </div>
  </v-form>

  <v-btn
    v-if="isAssetVisitTypeFilled && isAssetContainerDataFilled"
    :color="!areRequiredAssetDetailsFilled ? 'none' : 'primary'"
    @click="submit"
    :loading="submitting"
    class="mt-auto"
    :disabled="!areRequiredAssetDetailsFilled">
    Complete check-in
    <v-icon>mdi-arrow-right</v-icon>
  </v-btn>
  <v-btn
    v-else
    @click="handleAssetVisitTypeNext"
    :disabled="isAssetVisitTypeFilled && !isAssetContainerDataValid"
    :color="isAssetVisitTypeFilled && !isAssetContainerDataValid ? 'none' : 'primary'"
    class="mt-auto"
    :loading="submitting">
    Next
    <v-icon>mdi-arrow-right</v-icon>
  </v-btn>
</template>

<script lang="ts">
import axios from 'axios';
import { updateObjectInLocalStorage } from '@/components/mixins/localStorageMixin';
import useAssetVisitTypeForm from './composables/useAssetVisitTypeForm';
import { useCheckinForms, useAxiosClient, useRouter, useApiErrors } from '@/components/composables';
import { onMounted, ref, watch } from 'vue';
import type { PropType } from 'vue';
import { AssetVisitType, type IWarehouse } from '@nova/core';

export default {
  props: {
    latitude: {
      type: String,
      required: true
    },
    longitude: {
      type: String,
      required: true
    },
    warehouse: {
      type: Object as PropType<IWarehouse>,
      required: true
    }
  },
  emits: ['onError', 'onSuccess', 'back'],
  setup(props, context) {
    const submitting = ref(false);
    const unplannedform = ref(null);

    const {
      assetVisitType,
      assetContainerData,
      isAssetVisitTypeFilled,
      isAssetContainerDataFilled,
      isAssetContainerDataValid,
      handleAssetVisitTypeNext
    } = useAssetVisitTypeForm();

    const axiosClient = useAxiosClient();
    const router = useRouter();
    const { makeErrorScreenParams } = useApiErrors();

    const {
      assetDetailsFields,
      additionalAssetDetailsFields,
      additionalAssetDetailsTriggers,
      areRequiredAssetDetailsFilled,
      setRequiredAssetVisitFields,
      extractFieldsFromTriggers,
      getAssetDetailsData,
      additionalAssetDetailsFieldsExist,
      prepareCustomFormsData
    } = useCheckinForms(props.warehouse);

    function emitEvent(event: 'onError' | 'onSuccess', data: any) {
      return context.emit(event, data);
    }

    function setCustomFields() {
      additionalAssetDetailsFields.value = extractFieldsFromTriggers(
        additionalAssetDetailsTriggers.value
      );
    }
    async function submit() {
      const validation = await (unplannedform.value as any).validate();
      if (!validation?.valid) {
        return;
      }
      try {
        submitting.value = true;

        const assetDetails = getAssetDetailsData();
        assetDetails.assetVisitType = assetVisitType.value;

        const payload = {
          latitude: props.latitude,
          longitude: props.longitude,
          warehouseId: props.warehouse.id,
          customFormsData: prepareCustomFormsData(),
          assetDetails,
          assetContainerDetails: assetContainerData.value
        };

        const { data } = await axiosClient.put(
          `/checkin/public/${props.warehouse.id}/unplanned`,
          payload
        );
        if (data?.id) {
          emitEvent('onSuccess', data);
        }

        updateObjectInLocalStorage('comet:isPlanned', false);
        router.push({
          name: 'completed'
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // Handle case when check-in is successful, but message sending fails.
          // We display the success screen with a message to the user.
          const { message, details } = err.response?.data ?? {};
          if (
            message === 'Unable to send SMS message' &&
            details?.toNumber &&
            details?.fromNumber
          ) {
            router.push({
              name: 'completed',
              params: {
                errorTitle: 'Unable to send SMS message',
                errorMessage: `We couldn't send a messsage to ${details.toNumber}. <br /><br />
                 If you have previously unsubscribed, please text START to
                 <a href='sms:${details.fromNumber}'>${details.fromNumber}</a> to start receiving
                 SMS messages from this warehouse.`
              }
            });
            return;
          }

          const errorParams = makeErrorScreenParams(err);

          emitEvent('onError', errorParams);

          router.push({
            name: 'appointment-error',
            params: errorParams
          });
        }
      } finally {
        submitting.value = false;
      }
    }

    onMounted(() => {
      setCustomFields();
      setRequiredAssetVisitFields();
    });

    watch(
      () => props.warehouse,
      () => {
        setCustomFields();
        setRequiredAssetVisitFields();
      }
    );

    return {
      AssetVisitType,
      assetVisitType,
      assetContainerData,
      isAssetVisitTypeFilled,
      isAssetContainerDataFilled,
      isAssetContainerDataValid,
      handleAssetVisitTypeNext,
      submitting,
      areRequiredAssetDetailsFilled,
      submit,
      assetDetailsFields,
      additionalAssetDetailsFields,
      unplannedform,
      additionalAssetDetailsFieldsExist
    };
  }
};
</script>

<style scoped>
.summary {
  margin-bottom: 52px;
}

.header {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  color: #1e3036;
  margin-bottom: 20px;
}

.info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #3d545c;
  margin-top: 12px;
}
</style>
