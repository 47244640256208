import { AssetVisitType } from '@nova/core';
import { ref } from 'vue';

export default function useAssetVisitTypeForm() {
  const isAssetVisitTypeFilled = ref(false);
  const isAssetContainerDataFilled = ref(false);
  const isAssetContainerDataValid = ref(false);

  const assetVisitType = ref<AssetVisitType>(AssetVisitType.Live);
  const assetContainerData = ref<Record<string, string>[]>([]);

  const handleAssetVisitTypeNext = () => {
    if (!isAssetVisitTypeFilled.value) {
      isAssetVisitTypeFilled.value = true;
    } else {
      isAssetContainerDataFilled.value = true;
    }
  };

  return {
    isAssetVisitTypeFilled,
    isAssetContainerDataFilled,
    isAssetContainerDataValid,
    handleAssetVisitTypeNext,
    assetVisitType,
    assetContainerData
  };
}
