<template>
  <v-form ref="asset-visit-type-form">
    <v-card flat class="pa-4" v-if="!isAssetVisitTypeFilled">
      <v-card-title class="mb-4">
        <h3>What is the purpose of your stop?</h3>
      </v-card-title>
      <v-card-text>
        <v-radio-group v-model="assetVisitType" :mandatory="true" class="border px-6 pt-6">
          <v-radio
            v-for="option in assetVisitTypes"
            :key="option.value"
            :label="option.label"
            :value="option.value">
            <template #label>
              <div class="pa-2">
                <p>
                  <strong>{{ option.label }}</strong>
                </p>
                <div class="helper-text">{{ option.helperText }}</div>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>
    </v-card>
    <v-card flat class="pa-4" v-else>
      <div class="border pa-4 text-center mb-8">
        You Selected:
        <strong>{{ assetVisitTypes.find(v => v.value === assetVisitType).label }} visit</strong>
      </div>

      <h3>Asset Details</h3>
      <p>Please provide more details about the asset attached to your truck.</p>
      <div
        class="d-flex flex-column gap-4 mt-8 border pa-6 justify-content-center"
        v-for="(_, i) of assetContainerData || []"
        :key="i">
        <v-label>
          Asset Type
          <span class="text-error">*</span>
        </v-label>
        <v-select
          v-model="assetContainerData[i].containerType"
          :rules="[requiredValidator(assetContainerData[i].containerType, 'Trailer type')]"
          required
          variant="outlined"
          placeholder="Select the Asset type"
          :data-testid="`asset-visit-form-container-type-${i}`"
          :items="assetContainerTypes"
          item-title="title"
          item-value="value"
          class="flex-grow-1"></v-select>
        <v-label>
          Asset ID
          <span class="text-error">*</span>
        </v-label>
        <v-text-field
          v-model="assetContainerData[i].containerCode"
          placeholder="E.g: Trailer number, Container ID, etc"
          :data-testid="`asset-visit-form-container-code-${i}`"
          maxlength="50"
          required
          :rules="[requiredValidator(assetContainerData[i].containerCode, 'Trailer ID')]"
          variant="outlined"
          class="flex-grow-1"></v-text-field>
        <v-btn
          v-if="assetContainerData.length > 1 && i > 0"
          variant="text"
          color="error"
          click="assetContainerData"
          @click="assetContainerData.splice(i, 1)">
          Remove
        </v-btn>
      </div>
      <v-btn
        variant="text"
        block
        class="mt-8"
        v-if="assetContainerData.length < MAX_CONTAINER_DATA"
        @click="
          assetContainerData.push({ containerCode: '', containerType: AssetContainerType.Trailer })
        ">
        + Add another asset
      </v-btn>
    </v-card>
  </v-form>
</template>

<script>
import { AssetVisitType, AssetContainerType } from '@nova/core';
import { defineComponent, ref, watch } from 'vue';

const MAX_CONTAINER_DATA = 3;

export default defineComponent({
  name: 'AssetVisitTypeForm',
  emits: ['asset-visit-type-selected', 'asset-container-data-changed'],
  props: {
    value: {
      type: String,
      required: true
    },
    isAssetVisitTypeFilled: {
      type: Boolean,
      required: true
    },
    isAssetContainerDataFilled: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const assetVisitType = ref(props.value);
    const assetVisitTypes = ref([
      {
        value: AssetVisitType.Live,
        label: 'Live Load/Unload',
        helperText: 'I am loading or unloading my truck (FTL or LTL) while remaining on site.'
      },
      {
        value: AssetVisitType.Drop,
        label: 'Drop Only',
        helperText: 'I am leaving an asset (trailer, container, oversized, etc) at the facility.'
      },
      {
        value: AssetVisitType.PickUp,
        label: 'Pickup Only',
        helperText:
          'I am picking up an asset (trailer, container, oversized, etc) from the facility.'
      },
      {
        value: AssetVisitType.DropHook,
        label: 'Drop & Hook',
        helperText:
          'I am dropping off an asset (trailer, container, oversized, etc) and picking up another.'
      }
    ]);

    const assetContainerData = ref([
      { containerCode: '', containerType: AssetContainerType.Trailer }
    ]);

    const assetContainerTypes = [
      { title: 'Trailer', value: AssetContainerType.Trailer },
      { title: 'Container', value: AssetContainerType.Container },
      { title: 'Oversized', value: AssetContainerType.Oversized },
      { title: 'Other', value: AssetContainerType.Other }
    ];

    watch(
      () => props.value,
      newValue => {
        assetVisitType.value = newValue;
      }
    );

    watch(assetVisitType, newValue => {
      emit('asset-visit-type-selected', newValue);
    });
    watch(
      assetContainerData,
      newValue => {
        // Check if all asset container data is filled and at least one
        const isValid = newValue?.every(
          data => data.containerCode?.length > 0 && data.containerType?.length > 0
        );
        emit('asset-container-data-changed', { isValid: Boolean(isValid), data: [...newValue] });
      },
      { deep: true }
    );

    const requiredValidator = (v, l) => (!v || v?.length === 0 ? `${l} is required` : true);

    return {
      MAX_CONTAINER_DATA,
      requiredValidator,
      assetContainerData,
      assetContainerTypes,
      assetVisitType,
      assetVisitTypes,
      AssetVisitType,
      AssetContainerType
    };
  }
});
</script>

<style scoped>
.helper-text {
  font-size: 0.9rem;
}
</style>
