// @ts-strict-ignore
import { isString } from 'class-validator';
import { stringify } from 'querystring';
import Holidays from 'date-holidays';
import { ISO3166Countries } from './country';

export function getYearHolidaysForCountry(
  country: string,
  year?: number
): Record<string, string>[] {
  if (!year) {
    year = new Date().getFullYear();
  }

  if (country?.length === 3) {
    country = ISO3166Countries[country.toUpperCase()]?.iso2;
  }

  if (!country || !isString(country) || country.length > 3 || country.length < 2) {
    throw new Error('Invalid country');
  }

  const holidays = new Holidays(country.toLowerCase());
  const holidaysList = holidays.getHolidays(year);

  return holidaysList.map((holiday: any) => ({
    date: holiday.date.substring(0, 10),
    name: holiday.name
  }));
}
