<template>
  <v-overlay :model-value="show" persistent class="text-center align-center justify-center">
    <v-progress-circular color="primary" indeterminate size="42"></v-progress-circular>
    <p class="text-body-1 mt-4 font-weight-bold">{{ message }}</p>
  </v-overlay>
</template>

<script>
export default {
  name: 'LoadingOverlay',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  }
};
</script>
