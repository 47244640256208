import * as _ from 'lodash';

export enum CustomFormsFeatures {
  CheckOut = 'check-out',
  CheckIn = 'check-in',
  CheckInAdditionalAssetDetails = 'check-in additional asset details',
  InboundClaim = 'Inbound Claim',
  OutboundClaim = 'Outbound Claim',
  BookLoadType = 'Book Load Type',
  NavTracObservation = 'NavTrac Observation'
}

export const CustomFormsClaimFeatures = [
  CustomFormsFeatures.InboundClaim,
  CustomFormsFeatures.OutboundClaim
];

export const CustomFormsGateManagementFeatures = [
  CustomFormsFeatures.CheckIn,
  CustomFormsFeatures.CheckInAdditionalAssetDetails,
  CustomFormsFeatures.CheckOut
];

// These will get installed right after a warehouse is being created
export const CustomFormsFeaturesInstalledByDefault = [
  ...CustomFormsClaimFeatures,
  CustomFormsFeatures.CheckOut
];

export const CustomFormsFeaturesData = {
  [CustomFormsFeatures.CheckOut]: {
    title: 'Check-Out',
    description: 'Capture check-out data after an appointment is completed.',
    helpLink: ''
  },
  [CustomFormsFeatures.CheckIn]: {
    title: 'Check-in: Appointment Details',
    description:
      'Collect driver info as they self check-in for their planned appointment. This feature is an optional add-on to our Gate Management solution.',
    helpLink:
      '<a href="https://community.loadsmart.com/hc/en-us/articles/19326940613011-Carrier-Self-Check-In" target="_blank">Learn how it works</a> or contact us to <a href="https://lp.opendock.com/gate-management" target="_blank">request a trial.</a>'
  },
  [CustomFormsFeatures.CheckInAdditionalAssetDetails]: {
    title: 'Check-in: Asset Details',
    description:
      'Collect extra asset details for both planned and unplanned arrivals during driver self check-in. This feature is an optional add-on to our Gate Management solution.',
    enableDisclaimer: 'The drivers will see this form when they are checking themselves in.',
    disableDisclaimer: 'The drivers will not see this form when they are checking themselves in.',
    subtitle:
      '<i aria-hidden="true" class="v-icon notranslate mr-2 mdi mdi-information-outline" style="font-size: 12px;"></i> <strong>Phone number</strong> and <strong>carrier company</strong> will be collected by default.',
    helpLink:
      '<a href="https://community.loadsmart.com/hc/en-us/articles/19326940613011-Carrier-Self-Check-In" target="_blank">Learn how it works</a> or contact us to <a href="https://lp.opendock.com/gate-management" target="_blank">request a trial.</a>'
  },
  [CustomFormsFeatures.InboundClaim]: {
    title: 'Received Load Inspection',
    description: 'Document damage, discrepancies or other issues related to the received load.',
    helpLink: ''
  },
  [CustomFormsFeatures.OutboundClaim]: {
    title: 'Shipped Load Verification',
    description: 'Document load integrity and other security measures related to the shipped load.',
    helpLink: ''
  },
  [CustomFormsFeatures.BookLoadType]: {
    title: 'Appointment Creation per Load Type',
    description: 'Fields will show up when creating an appointment for a specific load type.',
    helpLink: ''
  },
  [CustomFormsFeatures.NavTracObservation]: {
    title: 'NavTrac Observation integration',
    description: 'Store NavTrac observation-related data',
    helpLink: ''
  }
};

/*
 Conditions to compare form values to transition forms
 */
export enum TriggerOperatorsEnum {
  greaterThan = '>',
  lessThan = '<',
  greaterThanOrEquals = '>=',
  lessThanOrEquals = '<=',
  equals = '=',
  notEquals = '<>'
}

export function applyFieldOperation(
  a: any,
  b: any,
  operator: TriggerOperatorsEnum,
  isEqual = _.isEqual
) {
  a = String(a);
  b = String(b);

  switch (operator) {
    case TriggerOperatorsEnum.equals:
      return isEqual(a, b);
    case TriggerOperatorsEnum.greaterThan:
      return a > b;
    case TriggerOperatorsEnum.lessThan:
      return a < b;
    case TriggerOperatorsEnum.notEquals:
      return !isEqual(String(a), String(b));
    case TriggerOperatorsEnum.greaterThanOrEquals:
      return a >= b;
    case TriggerOperatorsEnum.lessThanOrEquals:
      return a <= b;
    default:
      throw new Error('Unsupported operator');
  }
}

/*
  Target apps for custom forms
 */
export enum AppsEnum {
  Api = 'api',
  Warehouse = 'warehouse',
  Carrier = 'carrier',
  Driver = 'driver',
  All = 'all' // any request source will trigger
}

/*
 Entities that can hold custom forms data, mainly appointment will be used
 */
export enum CustomFormDataEntitiesEnum {
  appointment = 'appointment',
  assetVisit = 'assetvisit'
}

/*
 Entities that can have flows associated with, default is warehouse
 */
export enum CustomFormFlowEntitiesEnum {
  loadtype = 'loadtype',
  dock = 'dock',
  warehouse = 'warehouse'
}

/*
 * Event names that would trigger custom forms
 */
export enum CustomFormFlowCategoryEnum {
  appointmentStatusCompleted = 'appointmentStatusCompleted',
  appointmentStatusArrived = 'appointmentStatusArrived',
  appointmentCreated = 'appointmentCreated',
  assetVisitCreated = 'assetVisitCreated',
  manualTrigger = 'manualTrigger'
}

export class FieldExtraFields {
  constructor(
    public dropDownValues?: string[],
    public minLengthOrValue?: number,
    public maxLengthOrValue?: number
  ) {}
}
