import { DateTime } from 'luxon';
import { IAssetContainerEvent } from './asset-container-event';
import { INovaEntity } from './base';

export enum AssetContainerType {
  Trailer = 'Trailer',
  Container = 'Container',
  Oversized = 'Oversized',
  Other = 'Other'
}

export interface IAssetContainer extends INovaEntity {
  orgId: string;
  warehouseId: string;
  type: AssetContainerType;
  code: string;
  assetVisitId?: string | null;
  notes?: string | null;
}

export function getAssetContainerStatus(
  assetContainerEvents: IAssetContainerEvent[]
): string | null {
  if (Array.isArray(assetContainerEvents) && assetContainerEvents.length > 0) {
    // Get the last event based on the createDateTime
    const lastEvent = assetContainerEvents?.reduce((prev: any, current: any) => {
      return prev.createDateTime > current.createDateTime ? prev : current;
    });

    return lastEvent?.eventType || null;
  }

  return null;
}
