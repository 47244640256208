<template>
  <v-form ref="appointmentForm">
    <v-btn variant="text" prepend-icon="mdi-arrow-left" class="mb-4" @click="handleBackClick">
      <span>Back</span>
    </v-btn>
    <section v-if="isAssetVisitTypeFilled && isAssetContainerDataFilled">
      <div v-if="formPage === 1">
        <div class="summary">
          <appointment-summary :appointment="appointment"></appointment-summary>
          <div class="info">
            <strong>This is not your appointment?</strong>
            Please contact warehouse staff for check in instructions.
          </div>
        </div>
        <span class="text--color-text-tertiary">Step {{ formPage }}</span>
        <h2 class="header">Fill out the check-in form to continue</h2>
        <custom-fields
          :warehouse="appointmentWarehouse"
          :confirmation-number="appointment.confirmationNumber"
          v-if="checkInFieldsExist"
          :value="checkInFields"></custom-fields>
        <v-textarea
          v-model="notes"
          color="primary"
          placeholder="Would you like to add any notes for the warehouse? (optional)"
          variant="solo"
          data-testid="comet-appointment-notes"
          clearable></v-textarea>
      </div>
      <div v-if="formPage === 2">
        <span class="text--color-text-tertiary">Step {{ formPage }}</span>
        <h2 class="header">Fill out the asset information</h2>
        <v-alert
          v-if="inlineError.msg"
          dense
          outlined
          type="error"
          class="mb-3"
          :icon="inlineError.icon ?? 'mdi-alert-circle-outline'">
          {{ inlineError.msg }}
        </v-alert>
        <custom-fields
          :warehouse="appointmentWarehouse"
          :confirmation-number="appointment.confirmationNumber"
          :value="assetDetailsFields"></custom-fields>
        <custom-fields
          :warehouse="appointmentWarehouse"
          :confirmation-number="appointment.confirmationNumber"
          v-if="additionalAssetDetailsFieldsExist"
          :value="additionalAssetDetailsFields"></custom-fields>
      </div>
    </section>
    <div v-else>
      <asset-visit-type-form
        :value="assetVisitType"
        :is-asset-visit-type-filled="isAssetVisitTypeFilled"
        :is-asset-container-data-filled="isAssetContainerDataFilled"
        @asset-visit-type-selected="(v: AssetVisitType) => {
          assetVisitType = v;
          if (v === AssetVisitType.PickUp) {
            isAssetContainerDataFilled = true;
          }
        }"
        @asset-container-data-changed="
          ({ isValid, data } : any) => {
            isAssetContainerDataValid = isValid; 
            assetContainerData = data;
          }"
        class="mb-4" />
    </div>
  </v-form>

  <v-btn
    v-if="isAssetVisitTypeFilled && isAssetContainerDataFilled"
    :color="nextButtonDisabledState ? 'none' : 'primary'"
    @click="pageNext"
    :loading="submitting"
    class="mt-auto"
    :disabled="nextButtonDisabledState">
    {{ formPage < totalPages ? 'Continue' : 'Complete check-in' }}
    <v-icon>mdi-arrow-right</v-icon>
  </v-btn>
  <v-btn
    v-else
    @click="handleAssetVisitTypeNext"
    :disabled="isAssetVisitTypeFilled && !isAssetContainerDataValid"
    :color="isAssetVisitTypeFilled && !isAssetContainerDataValid ? 'none' : 'primary'"
    class="mt-auto"
    :loading="submitting">
    Next
    <v-icon>mdi-arrow-right</v-icon>
  </v-btn>
</template>

<script lang="ts">
import axios from 'axios';
import { type PropType } from 'vue';
import { updateObjectInLocalStorage } from '@/components/mixins/localStorageMixin';
import { useApiErrors, useCheckinForms } from '@/components/composables';
import { type IWarehouse, type IAppointment, AssetVisitType } from '@nova/core';
import useAssetVisitTypeForm from './composables/useAssetVisitTypeForm';

export default {
  props: {
    appointment: {
      type: Object as PropType<IAppointment>,
      required: true
    },
    latitude: {
      type: String,
      required: true
    },
    longitude: {
      type: String,
      required: true
    },
    warehouse: {
      type: Object as PropType<IWarehouse>,
      required: true
    }
  },
  emits: ['back'],
  setup(props) {
    const { makeErrorScreenParams } = useApiErrors();
    const {
      assetDetailsFields,
      additionalAssetDetailsFields,
      checkInFields,
      appointmentCompany,
      checkInFieldsExist,
      additionalAssetDetailsFieldsExist,
      checkInTriggers,
      additionalAssetDetailsTriggers,
      computedRequiredAssetVisitFields,
      areRequiredAssetDetailsFilled,
      areRequiredCheckinFieldsFilled,
      validateRequiredField,
      setRequiredAssetVisitFields,
      extractFieldsFromTriggers,
      getAssetDetailsData,
      prepareCustomFormsData
    } = useCheckinForms(props.warehouse, props.appointment);

    const {
      assetVisitType,
      assetContainerData,
      isAssetVisitTypeFilled,
      isAssetContainerDataFilled,
      isAssetContainerDataValid,
      handleAssetVisitTypeNext
    } = useAssetVisitTypeForm();

    return {
      AssetVisitType,
      assetVisitType,
      assetContainerData,
      isAssetVisitTypeFilled,
      isAssetContainerDataFilled,
      isAssetContainerDataValid,
      handleAssetVisitTypeNext,
      assetDetailsFields,
      additionalAssetDetailsFields,
      checkInFields,
      appointmentCompany,
      checkInFieldsExist,
      additionalAssetDetailsFieldsExist,
      checkInTriggers,
      additionalAssetDetailsTriggers,
      computedRequiredAssetVisitFields,
      areRequiredAssetDetailsFilled,
      areRequiredCheckinFieldsFilled,
      validateRequiredField,
      setRequiredAssetVisitFields,
      extractFieldsFromTriggers,
      getAssetDetailsData,
      prepareCustomFormsData,
      makeErrorScreenParams
    };
  },
  data() {
    return {
      submitting: false,
      formPage: 1,
      totalPages: 2,
      notes: '',
      inlineError: { msg: '', icon: '' }
    };
  },
  computed: {
    nextButtonDisabledState() {
      return (
        (this.formPage === 1 && !this.areRequiredCheckinFieldsFilled) ||
        (this.formPage === 2 && !this.areRequiredAssetDetailsFilled)
      );
    },
    appointmentWarehouse() {
      return this.appointmentDock?.warehouse;
    },
    appointmentDock() {
      return this.appointment.dock;
    }
  },
  methods: {
    setCustomFields() {
      this.checkInFields = this.extractFieldsFromTriggers(this.checkInTriggers);
      this.additionalAssetDetailsFields = this.extractFieldsFromTriggers(
        this.additionalAssetDetailsTriggers
      );
    },
    pageNext() {
      this.formPage < this.totalPages ? this.formPage++ : this.submit();
    },
    async submit() {
      const validation = await (this.$refs.appointmentForm as any).validate();
      if (!validation?.valid) {
        return;
      }
      this.inlineError.msg = '';
      try {
        this.submitting = true;
        updateObjectInLocalStorage('comet:appointment', this.appointment);

        const assetDetails = this.getAssetDetailsData();
        assetDetails.assetVisitType = this.assetVisitType;

        const payload = {
          latitude: this.latitude,
          longitude: this.longitude,
          appointmentId: this.appointment.id,
          assetDetails,
          customFormsData: this.prepareCustomFormsData(),
          notes: this.notes?.replace(/\n/g, '<br />'),
          assetContainerDetails: this.assetContainerData
        };

        const { data } = await this.axiosClient.put(
          `/checkin/public/${this.appointmentWarehouse?.id}`,
          payload
        );

        if (data?.id) {
          this.mixpanel.track('Completed: Self Check-in', {
            'Appointment ID': data.id,
            'Warehouse ID': this.appointmentWarehouse?.id,
            'Warehouse Name': this.appointmentWarehouse?.name,
            'Org ID': this.warehouse.orgId,
            'Org Name': this.warehouse.org?.name,
            'Appointment Start': data.start,
            'Appointment Validation #': data.refNumber || data.confirmationNumber,
            'Appointment Tags': data.tags,
            'Appointment Change': 'Status: Arrived',
            'Changed By': 'Self Check-in User',
            'Entry Point': 'Self Check-in',
            'Check-in Type': 'Planned Arrival'
          });

          if (payload.customFormsData?.length > 0) {
            this.mixpanel.track('Submitted: Custom Form', {
              'Custom Form Application': this.checkInTriggers[0].app,
              'Custom Form Type': this.checkInTriggers[0].feature,
              'Custom Form Trigger': this.checkInTriggers[0].category,
              'Appointment ID': data.id,
              'Warehouse ID': this.appointmentWarehouse?.id,
              'Warehouse Name': this.appointmentWarehouse?.name,
              'Org ID': this.warehouse.orgId,
              'Org Name': this.warehouse.org?.name,
              'Was Skipped': 'No',
              'Was Auto Triggered': 'Yes',
              'Entry Point': 'Self Check-in'
            });
          }
        }
        this.$router.push({
          name: 'completed'
        });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // Handle case when check-in is successful, but message sending fails.
          // We display the success screen with a message to the user.
          const { message, details } = err.response?.data ?? {};
          if (
            message === 'Unable to send SMS message' &&
            details?.toNumber &&
            details?.fromNumber
          ) {
            this.$router.push({
              name: 'completed',
              params: {
                errorTitle: 'Unable to send SMS message',
                errorMessage: `We couldn't send a messsage to ${details.toNumber}. <br /><br />
                 If you have previously unsubscribed, please text START to
                 <a href='sms:${details.fromNumber}'>${details.fromNumber}</a> to start receiving
                 SMS messages from this warehouse.`
              }
            });
            return;
          }

          const errorParams = this.makeErrorScreenParams(err);

          this.mixpanel.track('Error: Self Check In', {
            'Appointment Validation #':
              this.appointment.refNumber || this.appointment.confirmationNumber || 'N/A',
            'Warehouse ID': this.appointmentWarehouse?.id || 'N/A',
            'Warehouse Name': this.appointmentWarehouse?.name || 'N/A',
            Latitude: this.latitude || 'N/A',
            Longitude: this.longitude || 'N/A',
            'Error Type': errorParams.title
          });

          if (errorParams.showErrorInline !== '1') {
            this.$router.push({
              name: 'appointment-error',
              params: errorParams
            });
          } else {
            this.inlineError.msg = errorParams.title;
            this.inlineError.icon = errorParams.icon;
          }
        }
      } finally {
        this.submitting = false;
      }
    },
    handleBackClick() {
      this.formPage > 1 ? this.formPage-- : this.$emit('back');
    }
  },
  mounted() {
    this.setCustomFields();
    this.setRequiredAssetVisitFields();
  },
  watch: {
    warehouse() {
      this.setCustomFields();
      this.setRequiredAssetVisitFields();
    }
  }
};
</script>

<style scoped>
.summary {
  margin-bottom: 52px;
}

.header {
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  color: #1e3036;
  margin-bottom: 20px;
}

.info {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  color: #3d545c;
  margin-top: 12px;
}
</style>
